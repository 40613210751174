old subscription.vue
<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="review-order-start box-flex">
      <div class="container review-main text-capitalize position-relative review-container-nw box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">

            <div class="center-card" v-if="subscriptionItemCount > 0">
              <h4 class="p-2 mt-2">{{ $t("Subscribe") }}</h4>
              <div class="center-card-box | orders-tab-main p-3" v-if="productDetail"
                :id="'item-id-' + productDetail.item_id">

                <VueSlickCarousel v-bind="settings" id="prod-details-slider" class="silkprod"
                  v-if="productDetail.item_images.length > 0">
                  <div v-for="(slide, index) in productDetail.item_images" :key="index">
                    <div class="testimonial-item equal-height style-6">
                      <img class="item-img" :src="slide.image_name" @error="onImgError()" />
                    </div>
                  </div>
                </VueSlickCarousel>

                <!-- <VueSlickCarousel v-bind="settings" id="prod-details-slider" class="silkprod"
                v-if="filteredSubscriptions.length > 0">
                <div v-for="(slide, index) in filteredSubscriptions" :key="index">
                  <div class="testimonial-item equal-height style-6">
                    <img class="item-img" :src="slide.image" @error="onImgError()" />
                  </div>
                </div>
              </VueSlickCarousel> -->
                <div class="center-card-box | p-3">
                  <div class="d-flex align-items-center gap-3 justify-content-between">
                    <!-- {{ filteredSubscriptions }} -->
                    <skeleton-loader-vue v-if="!productDetail" type="string" width="50%" height="20px" />
                    <h5 class="modal-title" id="itemModalLabel">
                      {{ productDetail.item_name }}
                    </h5>

                    <div class="flex-shrink-0 d-flex align-items-center gap-2"
                      v-for="(item, index) in this.filteredSubscriptions" :key="index">
                      <p class="text-primary-400 fw-bold fs-5">
                        <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          item.price.toFixed(2) }}
                      </p>
                      <p class="text-decoration-line-through" v-if="item.price.toFixed(2) < item.mrp.toFixed(2)">
                        <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                          item.mrp.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                  <div class="tags-list" v-if="tags && tags.length > 0">
                    <div class="d-flex align-items-center justify-content-between gap-3">
                      <ul>
                        <li v-for="(tag, i) in tags" :key="i" :style="{
                          background: tag.background_color + '!important',
                          color: tag.text_color,
                        }">
                          {{ tag.name }}
                        </li>
                      </ul>
                      <div v-for="(item, index) in this.filteredSubscriptions" :key="index">
                        <p v-if="item.price.toFixed(2) - item.mrp.toFixed(2) > 0">
                          Save {{ item.price.toFixed(2) - item.mrp.toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- {{ this.getSubscription }} -->
                  <div v-for="(item, index) in this.getSubscription" :key="index">
                    <div class="customized customized-primary d-inline-block" v-if="item.selected_type.length > 0">
                      <p class="customized-lable text-start">customized</p>
                      <div class="selected_type flex-column">
                        <div class="selected-main">
                          <p v-for="(subitem, i) in item.selected_type" :key="i">
                            {{ subitem }}
                            <span v-if="
                              i !=
                              Object.keys(item.selected_type).length - 1
                            ">,</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="customized customized-primary d-inline-block"
                  v-if="productDetail.customize_type && productDetail.customize_type.length > 0">
                  <p class="customized-lable text-start">Customized</p>
                  <div class="selected_type flex-column">
                    <div class="selected-main">
                      <p v-for="(item, i) in productDetail.customize_type" :key="i">
                        <span v-for="(ite, j) in item.option" :key="j">
                          
                          {{ ite.selection_name }}
                          <span v-if="j !== ite.options.selection_name">,</span>
                          <span v-if="ite.options.length > 0" v-for="(items, j) in ite.options" :key="j">
                            {{ items.options }}
                            {{ items.selection_name }}
                          
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div> -->
                </div>
                <div class="container" v-if="customize_skelloader">
                  <div class="product-content pt-3">
                    <div>
                      <div class="cus-title">
                        <skeleton-loader-vue type="string" width="37%" height="20px" />
                        <skeleton-loader-vue type="string" width="20%" height="20px" />
                      </div>
                      <div>
                        <div class="">
                          <label for="radio-0-0" class="form__label-radio" style="padding-left: 0px">
                            <skeleton-loader-vue type="circle" class="form__radio" width="20px" height="20px"
                              style="margin-right: 20px" />
                            <skeleton-loader-vue type="string" width="170px" height="20px" style="position: absolute" />
                          </label>
                          <span class="ch-price">
                            <skeleton-loader-vue type="string" width="30px" height="24px" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form action="javascript:void(0)">
                  <div class="tab-content">
                    <div id="sort" class="tab-pane active tab-box">
                      <div class="container">
                        <!-- <div class="customized customized-primary d-inline-block"
                        v-if="productDetail.selected_type && productDetail.selected_type.length > 0">
                        <p class="customized-lable text-start">Customized</p>
                        <div class="selected_type flex-column">
                          <div class="selected-main">
                            <p v-for="(subitem, i) in productDetail.selected_type" :key="i">
                              {{ subitem.selection_name }}
                              <span v-if="i !== productDetail.selected_type.length - 1">,</span>
                            </p>
                          </div>
                        </div>
                      </div> -->


                        <div v-if="productDetail.customize_type.length > 0">
                          <!-- Toggle visibility on click -->
                          <!-- <a href="javascript:void(0);" @click="toggleCustomization" class="text-primary-400 mx-1">{{
                          $t("View Customization")
                          }}</a> -->
                        </div>
                        <div class="product-content pt-3"
                          v-if="isCustomizationVisible && productDetail.customize_type.length > 0 && !customize_skelloader">
                          <div v-for="(
                            customize, customizeIndex
                          ) in productDetail.customize_type" :key="customizeIndex">
                            <div class="cus-title">
                              <h6 class="cus-name">{{ customize.name }}</h6>
                              <p v-if="customize.is_selection != 0">
                                Maximum {{ customize.is_selection }} items
                              </p>
                            </div>
                            <!--Radio Button start-->
                            <div v-if="
                              (customize.is_min_selection == '1' &&
                                customize.is_selection == '1') ||
                              customize.is_selection == '1'
                            " :class="{
                              'is-invalid':
                                $v.selectedCustomisation.customize_type[
                                  'parent_' + customize.customize_type_id
                                ].value.$error,
                            }">
                              <div class="form-check form__radio-group" v-for="(optionData, index) in customize.option"
                                :key="index">
                                <div data-bs-toggle="collapse" class="rdiotogl" :href="'#collapseExample' + customizeIndex + index
                                  ">
                                  <input class="form__radio-input" type="radio" :name="'radio-' + customizeIndex" :id="'radio-' + customizeIndex + '-' + index
                                    " :value="optionData.selection_id" v-model="$v.selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value.$model
                                      " @change="
                                        checkNested(
                                          $event,
                                          customize.customize_type_id
                                        )
                                        " />
                                  <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' + index
                                    "><span class="form__radio-button"></span>{{
                                      optionData.selection_name }}</label>
                                  <span class="ch-price">
                                    <span v-html="$auth.setting.currency" class="currency"></span>
                                    {{ optionData.selection_price }}
                                    <b-icon-arrow-right-circle-fill
                                      v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill>
                                  </span>
                                </div>
                                <!--------Sub Customization Start Radio------------->
                                <div class="rdiotogllist" v-if="
                                  (optionData.is_min_selection == '1' &&
                                    optionData.is_selection == '1') ||
                                  (optionData.is_selection == '1' &&
                                    optionData.options.length > 0)
                                ">
                                  <div class="chld-rdio collapse show" :class="{
                                    'is-invalid':
                                      $v.selectedCustomisation.customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.$error,
                                  }" v-if="
                                    optionData.options.length > 0 &&
                                    selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value == optionData.selection_id
                                  ">
                                    <div v-for="(
                                      subOption, subi
                                    ) in optionData.options" :key="subi" class="form-check form__radio-group">
                                      <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                      <input class="form__radio-input" type="radio" :name="'radio-' +
                                        index +
                                        '-' +
                                        customizeIndex
                                        " :id="'radio-' +
                                          customizeIndex +
                                          '-' +
                                          index +
                                          '-' +
                                          subi
                                          " :value="subOption.selection_id" v-model="$v.selectedCustomisation
                                            .customize_type[
                                            'parent_' +
                                            customize.customize_type_id
                                          ]['child_' + optionData.selection_id]
                                            .value.$model
                                            " @change="
                                              checkNestedSub(
                                                $event,
                                                subOption.selection_id
                                              )
                                              " />
                                      {{ subOption.is_selected }}
                                      <label class="form__label-radio" :for="'radio-' +
                                        customizeIndex +
                                        '-' +
                                        index +
                                        '-' +
                                        subi
                                        "><span class="form__radio-button"></span>{{ subOption.selection_name
                                        }}</label>
                                      <span class="ch-price"><span v-html="$auth.setting.currency"
                                          class="currency"></span>{{
                                            subOption.selection_price }}</span>
                                    </div>
                                  </div>

                                  <div class="invalid-feedback">
                                    <span v-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.crequired
                                    ">{{ optionData.selection_name }}
                                      {{ $t("Maximum") }}
                                      {{ optionData.is_selection }}
                                      {{ $t("item select") }}</span>
                                    <span v-else-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.valueBetween
                                    ">{{ optionData.selection_name }}
                                      {{ $t("Maximum") }}
                                      {{ optionData.is_selection }}
                                      {{ $t("item select valid") }}</span>
                                  </div>
                                </div>
                                <!---------Sub Customization End Radio------------>
                                <!---------Sub Customization Start Checkbox------------>
                                <div v-else class="custom-checkbox w-100">
                                  <div class="chld-rdio collapse show" :class="{
                                    'is-invalid':
                                      $v.selectedCustomisation.customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.$error,
                                  }" v-if="
                                    optionData.options.length > 0 &&
                                    selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value == optionData.selection_id
                                  ">
                                    <div class="form-group" v-for="(
                                      suboptionCheckbox, subindex
                                    ) in optionData.options" :key="subindex">
                                      <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                                      <input class="form-check-input" type="checkbox"
                                        :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' +
                                          customizeIndex +
                                          '-' +
                                          index +
                                          '-' +
                                          subindex
                                          " :name="'subcheckbox_' +
                                            customizeIndex +
                                            index +
                                            '_' +
                                            subindex
                                            " v-model="$v.selectedCustomisation
                                              .customize_type[
                                              'parent_' +
                                              customize.customize_type_id
                                            ]['child_' + optionData.selection_id]
                                              .value.$model
                                              " @change="checkNestedSub($event)" />{{ suboptionCheckbox.is_selected }}
                                      <label class="form-check-label" :for="'subcheckbox-' +
                                        customizeIndex +
                                        '-' +
                                        index +
                                        '-' +
                                        subindex
                                        ">
                                        {{
                                          suboptionCheckbox.selection_name
                                        }}</label>
                                      <span class="ch-price"><span v-html="$auth.setting.currency"
                                          class="currency"></span>{{
                                            suboptionCheckbox.selection_price
                                          }}</span>
                                    </div>
                                  </div>
                                  <div class="invalid-feedback">
                                    <span v-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.crequired
                                    ">{{ optionData.selection_name }} Maximum
                                      {{ optionData.is_selection }} item
                                      select</span>
                                    <span v-else-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.valueBetween
                                    ">{{ optionData.selection_name }} Maximum
                                      {{ optionData.is_selection }} item select
                                      valid</span>
                                  </div>
                                </div>
                                <!---------Sub Customization End Checkbox------------>
                              </div>
                            </div>
                            <!--Radio Button end-->
                            <!--CheckBox start-->
                            <div v-else class="custom-checkbox" :class="{
                              'is-invalid':
                                $v.selectedCustomisation.customize_type[
                                  'parent_' + customize.customize_type_id
                                ].value.$error,
                            }">
                              <div v-for="(optionData, index) in customize.option" :key="index">
                                <div class="form-group">
                                  <input class="form-check-input" type="checkbox" :value="optionData.selection_id" :id="'checkbox-' + customizeIndex + '-' + index
                                    " :name="'checkbox_' + customizeIndex" v-model="$v.selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value.$model
                                      " @change="
                                        checkNestedSub(
                                          $event,
                                          customize.customize_type_id
                                        )
                                        " />
                                  <label class="form-check-label" :for="'checkbox-' + customizeIndex + '-' + index
                                    ">
                                    {{ optionData.selection_name }}
                                    {{ customize.customizeIndex }}</label>
                                  <span class="ch-price"><span v-html="$auth.setting.currency"
                                      class="currency"></span>{{
                                        optionData.selection_price }}
                                    <b-icon-arrow-right-circle-fill
                                      v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill></span>
                                </div>
                                <!--------Sub Customization Start Radio------------->
                                <div v-if="
                                  (optionData.is_min_selection == '1' &&
                                    optionData.is_selection == '1') ||
                                  (optionData.is_selection == '1' &&
                                    optionData.options.length > 0)
                                ">
                                  <div class="chld-rdio" :class="{
                                    'is-invalid':
                                      $v.selectedCustomisation.customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.$error,
                                  }" v-if="
                                    optionData.options.length > 0 &&
                                    selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value == optionData.selection_id
                                  ">
                                    <div v-for="(
                                      subOption, subi
                                    ) in optionData.options" :key="subi" class="form-check form__radio-group">
                                      <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                      <input class="form__radio-input" type="radio" :name="'radio-' +
                                        index +
                                        '-' +
                                        customizeIndex
                                        " :id="'radio-' +
                                          customizeIndex +
                                          '-' +
                                          index +
                                          '-' +
                                          subi
                                          " :value="subOption.selection_id" v-model="$v.selectedCustomisation
                                            .customize_type[
                                            'parent_' +
                                            customize.customize_type_id
                                          ]['child_' + optionData.selection_id]
                                            .value.$model
                                            " @change="checkNestedSub($event)" />
                                      {{ subOption.is_selected }}
                                      <label class="form__label-radio" :for="'radio-' +
                                        customizeIndex +
                                        '-' +
                                        index +
                                        '-' +
                                        subi
                                        "><span class="form__radio-button"></span>{{ subOption.selection_name
                                        }}</label>
                                      <span class="ch-price"><span v-html="$auth.setting.currency"
                                          class="currency"></span>{{
                                            subOption.selection_price }}</span>
                                    </div>
                                  </div>
                                  <div class="invalid-feedback">
                                    <span v-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.crequired
                                    ">{{ optionData.selection_name }}
                                      {{ $t("Maximum 1 item select") }}</span>
                                    <span v-else-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.valueBetween
                                    ">{{ optionData.selection_name }}
                                      {{
                                        $t("Maximum 1 item select valid")
                                      }}</span>
                                  </div>
                                </div>
                                <!---------Sub Customization End Radio------------>
                                <!---------Sub Customization Start Checkbox------------>
                                <div v-else class="custom-checkbox w-100">
                                  <div class="chld-rdio" :class="{
                                    'is-invalid':
                                      $v.selectedCustomisation.customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.$error,
                                  }" v-if="
                                    optionData.options.length > 0 &&
                                    selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value &&
                                    selectedCustomisation.customize_type[
                                      'parent_' + customize.customize_type_id
                                    ].value.includes(optionData.selection_id)
                                  ">
                                    <div class="form-group" v-for="(
                                      suboptionCheckbox, subindex
                                    ) in optionData.options" :key="subindex">
                                      <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                                      <input class="form-check-input" :data-id="customize.customize_type_id +
                                        ',' +
                                        optionData.selection_id
                                        " type="checkbox" :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' +
                                          customizeIndex +
                                          '-' +
                                          index +
                                          '-' +
                                          subindex
                                          " v-model="$v.selectedCustomisation
                                            .customize_type[
                                            'parent_' +
                                            customize.customize_type_id
                                          ]['child_' + optionData.selection_id]
                                            .value.$model
                                            " @change="
                                              checkNestedSub(
                                                $event,
                                                suboptionCheckbox.customize_type_id,
                                                suboptionCheckbox.parent_item_id
                                              )
                                              " />{{ suboptionCheckbox.is_selected }}
                                      <label class="form-check-label" :for="'subcheckbox-' +
                                        customizeIndex +
                                        '-' +
                                        index +
                                        '-' +
                                        subindex
                                        ">
                                        {{
                                          suboptionCheckbox.selection_name
                                        }}</label>
                                      <span class="ch-price"><span v-html="$auth.setting.currency"
                                          class="currency"></span>{{
                                            suboptionCheckbox.selection_price
                                          }}</span>
                                    </div>
                                  </div>
                                  <div class="invalid-feedback">
                                    <span v-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.crequired
                                    ">{{ optionData.selection_name }}
                                      {{ $t("Maximum") }}
                                      {{ optionData.is_selection }}
                                      {{ $t("item select") }}</span>
                                    <span v-else-if="
                                      !$v.selectedCustomisation
                                        .customize_type[
                                        'parent_' +
                                        customize.customize_type_id
                                      ]['child_' + optionData.selection_id]
                                        .value.valueBetween
                                    ">{{ optionData.selection_name }}
                                      {{ $t("Maximum") }}
                                      {{ optionData.is_selection }}
                                      {{ $t("item select valid") }}</span>
                                  </div>
                                </div>
                                <!---------Sub Customization End Checkbox------------>
                              </div>
                            </div>
                            <!--CheckBox end-->
                            <div class="invalid-feedback" v-if="
                              $v.selectedCustomisation.customize_type[
                                'parent_' + customize.customize_type_id
                              ].value.$error
                            ">
                              <span v-if="
                                !$v.selectedCustomisation.customize_type[
                                  'parent_' + customize.customize_type_id
                                ].value.crequired
                              ">
                                <span v-if="customize.is_min_selection == 1">{{
                                  customize.name }}
                                  {{
                                    $t("Required Minimun 1 item select")
                                  }}</span>
                                <span v-if="customize.is_min_selection != 1">{{
                                  customize.name }}
                                  {{ $t("Required Minimum") }}
                                  {{ customize.is_min_selection }}
                                  {{ $t("items") }}</span>
                              </span>
                              <span v-else-if="
                                !$v.selectedCustomisation.customize_type[
                                  'parent_' + customize.customize_type_id
                                ].value.valueBetween
                              ">
                                <span v-if="customize.is_min_selection == 1">{{
                                  customize.name }}
                                  {{
                                    $t("Required Minimun 1 item select")
                                  }}</span>
                                <span v-if="customize.is_min_selection != 1">{{
                                  customize.name }}
                                  {{ $t("Required Minimum") }}
                                  {{ customize.is_selection }}
                                  {{ $t("items") }}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="center-card-box">
                          <div class="card">
                            <div class="card-body | py-3">

                              <div
                                class="d-lg-flex d-grid align-items-center mt-4 mb-3 justify-content-lg-between gap-3 mx-lg-5">
                                <div class="text-lg-center">
                                  <h4 class="date-label | text-primary-400">
                                    {{ $t("Starts On") }}
                                  </h4>
                                  <input type="date" id="dateStartInput" v-model="subscription_start_date"
                                    :min="minStartDate" @input="setMinDate" class="mt-2" />
                                </div>
                                <div class="d-none d-lg-block">
                                  <i class="fas fa-arrow-right"></i>
                                </div>
                                <div class="text-lg-center position-relative">
                                  <h4 class="date-label | text-primary-400">
                                    {{ $t("Ends On") }}
                                  </h4>

                                  <div class="d-flex flex-column">
                                    <label>{{ $t("(Optional)") }}</label>
                                    <input type="date" class="text-center" id="dateEndInput" :min="minEndDate"
                                      v-model="subscription_end_date" @change="handleChange" />
                                  </div>
                                  <!-- {{ subscription_end_date }} -->
                                </div>
                              </div>
                              <p>
                                {{ $t("Your first order will be delivered on") }}
                                <span class="card-date">{{ getDeliveryDay }} at
                                  <span>{{ this.restaurantSetting &&
                                    this.restaurantSetting.subscription_delivery_time
                                    != '' ?
                                    this.restaurantSetting.subscription_delivery_time
                                    : "" }}</span></span>
                              </p>
                            </div>
                            <!-- <div class="card-footer | bg-transparent py-3">
                            <p class="text-danger">
                              Minimum order value should be
                              <span v-html="$auth.setting && $auth.setting.currency
                                " class="currency"></span>{{ restaurantData.minimum_order_value }} for
                              free delivery
                            </p>
                          </div> -->
                          </div>
                        </div>
                        <div class="center-card-box pt-3">
                          <h4>{{ $t("Select Frequency") }}</h4>
                          <div class="card | mt-3">
                            <div class="card-header | bg-transparent py-3 d-flex justify-content-center">
                              <div class="d-flex align-items-center flex-wrap gap-3">
                                <!-- <button :class="[
                                'modal-btn',
                                'rounded-pill',
                                { active: this.subscription_type === 1 },
                              ]" data-bs-toggle="modal" data-bs-target="#everyDayModal">
                                Everyday
                              </button> -->

                                <button :class="[
                                  'modal-btn',
                                  'rounded-pill',
                                  { active: this.subscription_type == 1 },
                                ]" data-bs-toggle="modal" @click="subscriptionModal(1)">
                                  <!-- ]" data-bs-toggle="modal" @click="subscriptionModal(1)"> -->
                                  {{ $t("Everyday") }}
                                </button>

                                <button :class="['modal-btn', 'rounded-pill',
                                  { active: this.subscription_type == 2 },]" data-bs-toggle="modal"
                                  @click="subscriptionModal(2)">
                                  {{ $t("On Interval") }}
                                </button>

                                <button :class="[
                                  'modal-btn',
                                  'rounded-pill',
                                  { active: this.subscription_type == 3 },
                                ]" data-bs-toggle="modal" @click="subscriptionModal(3)">
                                  {{ $t("Custom") }}
                                </button>
                              </div>
                            </div>
                            <div class="card-body">
                              <!-- <div class="date-wrapper | text-center" v-if="this.subscription_type == 3 && isShowText">
                              <ul class="date-list">
                                <li class="date-item" v-for="(day, index) in day_date_value" :key="index"
                                  @click="showCustomModal">
                                  <div class="date-day">{{ day.day.slice(0, 3) }}</div>
                                  <div class="date-num">
                                    {{ day.quantity }}
                                  </div>
                                </li>
                              </ul>
                            </div> -->

                              <div class="date-wrapper | text-center">
                                <template v-if="subscription_type == 3 && isShowText">
                                  <ul class="date-list" v-if="day_date_value && day_date_value.length > 0">
                                    <li class="date-item" v-for="(day, index) in day_date_value" :key="index"
                                      @click="showCustomModal">
                                      <div class="date-day">{{ day.day.slice(0, 3)
                                        }}</div>
                                      <div class="date-num">
                                        {{ day.quantity }}
                                      </div>
                                    </li>
                                  </ul>
                                  <p v-else>
                                    {{ $t("How many Quantity would you like to
                                    Deliver Every Day ? ") }}
                                  </p>
                                </template>
                              </div>

                              <div class="date-wrapper | text-center">
                                <!-- Condition for subscription_type == 2 and isShowText -->
                                <template v-if="subscription_type == 2 && isShowText">
                                  <!-- Check if intervalDay is valid and has a quantity -->
                                  <p v-if="intervalDay && intervalDay.quantity">
                                    {{ intervalDay.quantity }}
                                    {{ $t("Quantity Deliver on Every") }} {{
                                      getOrdinal(intervalDay.value) }} Day
                                  </p>
                                  <!-- Fallback if intervalDay is empty or invalid -->
                                  <p v-else>
                                    {{ $t("How many Quantity would you like to
                                    Deliver Every Day ? ") }}
                                  </p>
                                </template>
                              </div>
                              <!-- {{ this.intervalDay }}
                            <div class="date-wrapper | text-center" v-if="this.subscription_type == 2 && isShowText">
                              <p>
                                {{ this.intervalDay.quantity ? this.intervalDay.quantity : this.intervalData.quantity
                                }} Quantity
                                Deliver on Every {{ getOrdinal(this.intervalDay.value ? this.intervalDay.value :
                                  this.intervalData.value) }} Day
                              </p>
                            </div> -->
                              <div class="date-wrapper | text-center" v-if="this.subscription_type == 1 && isShowText">
                                <p>
                                  {{ this.getSubscription[0].quantity }} {{
                                    $t("Quantity Deliver Every Day") }} </p>
                              </div>

                              <div class="date-wrapper | text-center" v-if="this.subscription_type == 0 || !isShowText">
                                <p>
                                  {{ $t("How many Quantity would you like to Deliver
                                  Every Day ? ") }}
                                </p>
                              </div>

                              <!-- <div
                                  class="d-flex align-items-center justify-content-center gap-3 mt-4"
                                >
                                  <p class="text-primary-400">QTY : <span>200</span></p>
                                  <a href="#" class="text-primary-400"
                                    ><i class="fas fa-pen"></i
                                  ></a>
                                </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="cusines" class="tab-pane tab-box">
                      <div class="accordion mt-4" id="accordionExample">
                        <div class="card" v-if="productDetail.item_description">
                          <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                              <button class="btn btn-link btn-block text-start collapsed w-100" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne">
                                {{ $t("Description") }}
                              </button>
                            </h2>
                          </div>
                          <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                              {{ productDetail.item_description }}
                            </div>
                          </div>
                        </div>
                        <div v-if="productDetail.custom_details.length > 0">
                          <div class="card" v-for="(
                            detail, index
                          ) in productDetail.custom_details" :key="index + 1">
                            <div class="card-header" :id="`headingOne-${index}`">
                              <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-start collapsed w-100" type="button"
                                  data-bs-toggle="collapse" :data-bs-target="`#collapseOne-${index}`"
                                  aria-expanded="true" :aria-controls="`collapseOne-${index}`">
                                  {{ detail.custom_key }}
                                </button>
                              </h2>
                            </div>
                            <div :id="`collapseOne-${index}`" class="collapse" :aria-labelledby="`headingOne-${index}`"
                              data-parent="#accordionExample">
                              <div class="card-body">
                                {{ detail.custom_value }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="center-card-box | p-3">
                <div class="full-btn">
                  <button @click="buySubscription">
                    {{ $t("Next") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <subscription-modal v-if="subscriptionModalOPen" :type="subscription_type"
      @subscriptionModalClose="closeSubscriptionModal" @isShowText="isShowText = true"
      @selectedData="manageSubscriptiopData"></subscription-modal>
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import NavBar from "../components/NavBar.vue";
import DeliveryTime from "../components/DeliveryTime.vue";
import Footer from "../components/Footer.vue";
import moment, { min } from "moment";
import VueSkeletonLoader from "skeleton-loader-vue";
import noImge from "@/assets/images/place_holder.png";
import { requiredIf } from "vuelidate/lib/validators";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Vue from "vue";
import SubscriptionCart from './SubscriptionCart.vue';
import SubscriptionModal from './SubscriptionModal.vue';
import Login from "../apis/login";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ['modalTypes', 'SubcriModals'],
  // props: {
  //   openCartModal: Boolean,
  //   modalTypes: String
  // },
  name: 'subscription',
  components: {
    DatePicker,
    NavBar,
    DeliveryTime,
    Footer,
    "skeleton-loader-vue": VueSkeletonLoader,
    VueSlickCarousel,
    SubscriptionCart,
    SubscriptionModal,
  },
  data() {
    return {
      isShowText: false,
      subscriptionModalOPen: false,
      userWalletStatus: null,
      minEndDate: '',
      minStartDate: this.getTomorrowDate(),
      restaurantSetting: '',
      // todayDate: new Date().toISOString().split("T")[0],
      selectedCustomisation: {
        customize_type: {},
      },
      everyDay_quantity: 0,
      intervalModalvalue: '',
      settings: {
        autoplay: true,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        centerPadding: "40px",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      subscription_start_date: null,
      subscription_end_date: null,
      subscription_type: 0,
      interval: {},
      // intervalOptions: [
      //   { value: "2", label: "Every 2nd day", quantity: 1, isChecked: true },
      //   { value: "3", label: "Every 3rd day", quantity: 0, isChecked: false },
      //   { value: "4", label: "Every 4th day", quantity: 0, isChecked: false },
      //   { value: "n", label: "Every nth day", quantity: 0, isChecked: false },
      // ],
      tags: [],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      // selectedDays: [
      //   { key: "Sun", day: "Sunday", isChecked: true, quantity: 1 },
      //   { key: "Mon", day: "Monday", isChecked: true, quantity: 1 },
      //   { key: "Tue", day: "Tuesday", isChecked: true, quantity: 1 },
      //   { key: "Wed", day: "Wednesday", isChecked: true, quantity: 1 },
      //   { key: "Thu", day: "Thursday", isChecked: true, quantity: 1 },
      //   { key: "Fri", day: "Friday", isChecked: true, quantity: 1 },
      //   { key: "Sat", day: "Saturday", isChecked: true, quantity: 1 },
      // ],
      selectedDays: [],

      day_full_name: null,
      currentModal: null,

      date_day: [
        { key: "Sun", day: "Sunday", value: 0 },
        { key: "Mon", day: "Monday", value: 0 },
        { key: "Tue", day: "Tuesday", value: 0 },
        { key: "Wed", day: "Wednesday", value: 0 },
        { key: "Thu", day: "Thursday", value: 0 },
        { key: "Fri", day: "Friday", value: 0 },
        { key: "Sat", day: "Saturday", value: 0 },
      ],
      intervalData: '',
      customize_skelloader: true,
      everyDayModal: false,
      intervalDay: [],
      everyDay: [],
      customDayModal: false,
      moment: moment,
      productDetail: null,
      restaurantData: "",
      getDeliveryDay: "",
      gstMaxtax: 0,
      subscriptionItemCount: 1,
      promoDiscount: 0,
      restaurantId: 0,
      itemId: 0,
      latitude: "",
      longitude: "",
      restaurantLat: "",
      restaurantLong: "",
      page: "review_page",
      isAsapEnable: false,
      timeFormat: "HH:mm:ss",
      isRestaurantClosed: false,
      isSameDayDelivery: false,
      is_btn_spinner: true,
      images: [],
      selectedCustomisation: {
        customize_type: {},
      },
      selectedCheckbox: [],
      orderImagesId: "",
      loading: false,
      noImge,
      instructionPlaceholder: "",
      packagingCharge: 0,
      convince_charge: 0,
      isTemplateShow: false,
      isAlert: false,
      isCustomizationVisible: false,
      subInervalModal: false,
      subscriptionObj: {
        restaurant_id: 0,
        vendor_id: null,
        user_id: "",
        // user_id: this.$auth.user.user_id,
        wallet_amount: 0,
        address_id: null,
        restaurant_service_fee: 0,
        device_type: "WEB",
        is_langauge: "en",
        app_version_code: 3.4,
        payment_method: "Wallet",
        subscription_type: 0,
        interval: 0,
        custom_days: [],
        start_date: null,
        end_date: null,
        restaurant_item_details: null,
        payment_type: "wallet",
      },
    };
  },
  validations() {
    if (this.productDetail) {
      let data = {
        selectedCustomisation: {
          customize_type: {},
        },
      };
      this.productDetail &&
        this.productDetail.customize_type.forEach((item, index) => {
          let pvalue = null;
          if (
            !this.selectedCustomisation.customize_type[
            "parent_" + item.customize_type_id
            ]
          ) {
            this.selectedCustomisation.customize_type[
              "parent_" + item.customize_type_id
            ] = { value: item.is_selection != 1 ? [] : null };
          }
          pvalue =
            this.selectedCustomisation.customize_type[
              "parent_" + item.customize_type_id
            ].value;
          data["selectedCustomisation"]["customize_type"][
            "parent_" + item.customize_type_id
          ] = {
            value: {
              crequired: (value) => {
                return item.is_min_selection > 0
                  ? value && value.toString().length > 0
                    ? true
                    : false
                  : true;
              },
              valueBetween: (value) =>
                item.is_selection > 1
                  ? value.length >= item.is_min_selection &&
                  value.length <= item.is_selection
                  : true,
            },
          };
          this.productDetail.customize_type[index].option.forEach((op, ind) => {
            if (
              op.is_default == 1 &&
              this.selectedCustomisation.customize_type[
                "parent_" + op.customize_type_id
              ].flag !== false
            ) {
              //this.isDefaultCheck = true;
              let setvalue =
                this.selectedCustomisation.customize_type[
                  "parent_" + op.customize_type_id
                ].value;
              if (setvalue && setvalue != null) {
                if (
                  typeof setvalue == "number" ||
                  typeof setvalue == "string"
                ) {
                  setvalue = op.selection_id;
                } else {
                  if (!setvalue.includes(op.selection_id)) {
                    setvalue.push(op.selection_id);
                  }
                }
              } else {
                this.selectedCustomisation.customize_type[
                  "parent_" + op.customize_type_id
                ].value = op.selection_id;
              }
            }
            this.selectedCustomisation.customize_type[
              "parent_" + op.customize_type_id
            ].flag = false;
            let temp =
              this.selectedCustomisation.customize_type[
              "parent_" + item.customize_type_id
              ]["child_" + op.selection_id];
            if (
              !temp ||
              (pvalue &&
                typeof pvalue == "object" &&
                (pvalue.length == 0 || !pvalue.includes(op.selection_id))) ||
              pvalue == null ||
              pvalue == ""
            ) {
              if (op.is_selection < op.is_min_selection) {
                this.productDetail.customize_type[index].option[
                  ind
                ].is_selection = op.is_min_selection;
              }
              this.selectedCustomisation.customize_type[
                "parent_" + item.customize_type_id
              ]["child_" + op.selection_id] = {
                value:
                  op.is_selection != 1
                    ? []
                    : op.is_selection < op.is_min_selection
                      ? []
                      : null,
              };
              this.selectedCustomisation.customize_type[
                "parent_" + item.customize_type_id
              ]["child_" + op.selection_id].flag = true;
              //this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag = true;
            }
            if (op.options.length > 0) {
              op.options.forEach((itm, key) => {
                //if(itm.is_default == 1  && !this.isChildDefaultCheck) {
                if (
                  itm.is_default == 1 &&
                  this.selectedCustomisation.customize_type[
                    "parent_" + item.customize_type_id
                  ]["child_" + op.selection_id].flag !== false
                ) {
                  let setopvalue =
                    this.selectedCustomisation.customize_type[
                      "parent_" + op.customize_type_id
                    ]["child_" + itm.parent_item_id].value;
                  if (setopvalue != null) {
                    if (
                      typeof setopvalue == "number" ||
                      typeof setopvalue == "string"
                    ) {
                      setopvalue = itm.selection_id;
                    } else {
                      if (setopvalue.length > 0) {
                        setopvalue = setopvalue.filter((c, index) => {
                          return setopvalue.indexOf(c) === index;
                        });
                      }
                      setopvalue.push(itm.selection_id);
                    }
                  }
                  //this.isChildDefaultCheck = true;
                }
              });
            } else {
              if (
                op.is_default == 1 &&
                this.selectedCustomisation.customize_type[
                  "parent_" + item.customize_type_id
                ]["child_" + op.selection_id].flag !== false
              ) {
                let setopvalue =
                  this.selectedCustomisation.customize_type[
                    "parent_" + op.customize_type_id
                  ].value;
                if (setopvalue == null) {
                  this.selectedCustomisation.customize_type[
                    "parent_" + op.customize_type_id
                  ].value = op.selection_id;
                }
              }
            }
            this.selectedCustomisation.customize_type[
              "parent_" + item.customize_type_id
            ]["child_" + op.selection_id].flag = false;
            data["selectedCustomisation"]["customize_type"][
              "parent_" + item.customize_type_id
            ]["child_" + op.selection_id] = {
              value: {
                crequired: (value) => {
                  if (typeof value != "object") {
                    return op.is_min_selection > 0 &&
                      (pvalue && typeof pvalue == "object"
                        ? pvalue.includes(op.selection_id)
                        : pvalue == op.selection_id)
                      ? value && value.toString().length > 0
                        ? true
                        : false
                      : true;
                  } else {
                    return op.is_min_selection > 0 &&
                      (pvalue && typeof pvalue == "object"
                        ? pvalue.includes(op.selection_id)
                        : pvalue == op.selection_id)
                      ? value && value.length > 0
                        ? true
                        : false
                      : true;
                  }
                },

                valueBetween: (value) => {
                  if (typeof value != "object") {
                    return op.is_selection > 1 &&
                      (pvalue && typeof pvalue == "object"
                        ? pvalue.includes(op.selection_id)
                        : pvalue == op.selection_id)
                      ? value &&
                      value.toString().length >= op.is_min_selection &&
                      value &&
                      value.toString().length <= op.is_selection
                      : true;
                  }
                  return op.is_selection > 1 &&
                    (pvalue && typeof pvalue == "object"
                      ? pvalue.includes(op.selection_id)
                      : pvalue == op.selection_id)
                    ? value &&
                    value.length >= op.is_min_selection &&
                    value &&
                    value.length <= op.is_selection
                    : true;
                },
              },
            };
          });
        });
      //this.isDefaultCheck = false;
      return data;
    } else {
      return {};
    }
  },

  // beforeCreate() {
  //   let previousData = JSON.parse(localStorage.getItem("subscribed-data"));
  //   if (previousData && previousData != "" && previousData != null) {
  //     this.subscription_type = previousData.subscription_type;
  //   }
  // },





  mounted() {
    setTimeout(() => {
      if (this.$auth && this.$auth.user) {
        // this.subType = this.subscriptionType
        this.isTemplateShow = true;
        // this.restaurantId = parseInt(localStorage.getItem("restaurantID"), 10);
        this.restaurantId = this.base64_decode(localStorage.getItem("restaurantId"), 10);
        this.SubRestaurantSettings()
        this.itemId = JSON.parse(localStorage.getItem("itemId"));
        this.token = this.$auth.user.token;
        this.userId = this.$auth.user.user_id;
        this.latitude = localStorage.getItem("latitude");
        this.longitude = localStorage.getItem("longitude");
        let currentTime = moment().format(this.timeFormat);
        if (this.$auth.setting) {
          let system_start_time =
            this.$auth.setting && this.$auth.setting.system_start_time != ""
              ? this.$auth.setting.system_start_time.split(" ").pop()
              : "00:00:00";
          let system_end_time =
            this.$auth.setting && this.$auth.setting.system_end_time != ""
              ? this.$auth.setting &&
              this.$auth.setting.system_end_time.split(" ").pop()
              : "23:59:00";
          this.isSameDayDelivery =
            this.$auth.setting && this.$auth.setting.is_same_day_delivery == 0
              ? false
              : true;
          let beforeTime = moment(system_start_time, this.timeFormat);
          let afterTime = moment(system_end_time, this.timeFormat);
          if (
            moment(currentTime, this.timeFormat).isBetween(
              beforeTime,
              afterTime
            )
          ) {
            this.isAsapEnable = true;
          } else {
            this.isAsapEnable = false;
          }
        }
      } else {
        this.$router.push({ name: "sign-in" });
      }
      this.$nextTick(() => {
        this.subscription_start_date = this.getTomorrowDate();

        //   const formattedDate = tomorrow.toDateString();
        // document.getElementById("dateStartInput").setAttribute("min", this.subscription_start_date);
      });

      if (
        this.restaurant_on_off == "0" &&
        this.$auth.setting &&
        this.$auth.setting.is_enable_schedule_order_after_business_hours == 0
      ) {
        this.closeLabel = true;
      } else {
        this.closeLabel = false;
      }
      this.getProductData(this.itemId, this.restaurantId);
      this.getRestaurantInfo();
      this.getUserDetail()
      // let previousData = JSON.parse(localStorage.getItem("subscription_products"));
      let previousData = JSON.parse(localStorage.getItem("subscriptionModal_data"));
      if (previousData && previousData != "" && previousData != null) {
        this.subscription_type = previousData.type;
        if (this.subscription_type == 1) {
          // this.getSubscription[0].quantity =
          //   previousData.data[0].quantity;
          this.everyDay_quantity =
            previousData.data[0].quantity;
        }

        if (this.subscription_type == 2) {
          this.intervalDay = JSON.parse(localStorage.getItem("setIntervalday"));
          // previousData.data.forEach((e) => {
          //   if (e.value == this.intervalData.value) {
          //     e.quantity = this.intervalData.quantity
          //     e.isChecked = true;
          //     this.interval = e;
          //   } else {
          //     e.isChecked = false;
          //     e.quantity = 0;
          //   }
          // });
          // this.getSubscription[0].quantity = previousData.restaurant_item_details[0].quantity
        }
        if (this.subscription_type == 3) {
          this.selectedDays = previousData.data;
          this.selectedDays.forEach((e) => {
            if (e.quantity != 0) {
              e.isChecked = true;
            }
          });
        }

        // let previousDataCustom = JSON.parse(localStorage.getItem("subscriptionModal_data"));
        // if (this.subscription_type == 3) {
        //   this.selectedDays = JSON.parse(previousDataCustom);
        //   this.selectedDays.forEach((e) => {
        //     if (e.quantity != 0) {
        //       e.isChecked = true;
        //     }
        //   });
        // }

        this.subscription_start_date = this.reformateDate(
          previousData.start_date
        );
      }
    }, 500);

    let sub_end_date = localStorage.getItem("subscription_end_date");
    this.subscription_end_date = sub_end_date

    //check subscription_type getting the show text
    let previousData = localStorage.getItem("subscribedType");
    if (previousData && previousData > 0) {
      this.subscription_type = previousData;
    }
    if (this.subscription_type && this.subscription_type > 0) {
      this.isShowText = true;
    }
    //check subscription_type getting the show text
  },

  computed: {
    ...mapGetters("product", ["getSubscription", "products", "getSubscriptionItem"]),
    ...mapGetters("restaurant", ["restaurantDetails"]),
    ...mapGetters("user", ["userWallet"]),

    onModalCloseFun(value) {
      this.isAlert = false;
    },

    setMinDate() {
      let today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      // const formattedDate = tomorrow.toDateString();
      let dd = String(tomorrow.getDate()).padStart(2, "0");
      let mm = String(tomorrow.getMonth() + 1).padStart(2, "0"); // January is 0!
      let yyyy = tomorrow.getFullYear();
      tomorrow = yyyy + "-" + mm + "-" + dd;

      // this.$nextTick(() => {
      //     document.getElementById("dateEndInput").setAttribute("min", tomorrow);
      // });

      if (this.subscription_start_date) {
        let nextDay = new Date(this.subscription_start_date);

        // if (this.restaurantDetails.is_pre_order == '1' && this.restaurantDetails.is_pre_order_type == "days") {
        //   const skipDays = Number(this.restaurantDetails.pre_order_time) || 0;
        //   let today = new Date(); // Get the current date
        //   // Create a new date based on today, add skipDays to it
        //   let calculatedDate = new Date(today); // Clone today's date to avoid modifying `today`
        //   calculatedDate.setDate(today.getDate() + skipDays); // Add the skipped days to the calculated date
        //   const dd = String(calculatedDate.getDate()).padStart(2, "0");
        //   const mm = String(calculatedDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        //   const yyyy = calculatedDate.getFullYear();
        //   const formattedDate = `${yyyy}-${mm}-${dd}`;
        //   this.subscription_start_date = formattedDate;
        // }

        let daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        let dayOfWeek = daysOfWeek[nextDay.getDay()];
        this.getDeliveryDay = moment(this.subscription_start_date).format('ddd DD MMM YYYY')
        nextDay.setDate(nextDay.getDate() + 1);
        // let nextDayFormatted = nextDay.toString().split(' ').slice(0, 4).join(' ');
        let nextDayFormatted = nextDay.toISOString().split("T")[0];
        let dateEndInput = document.getElementById("dateEndInput");
        if (dateEndInput) {
          dateEndInput.setAttribute("min", nextDayFormatted);
        } else {
          console.error("Element with id dateEndInput not found.");
        }
        // document.getElementById("dateEndInput").setAttribute("min", nextDayFormatted);
      }

      // if (this.subscription_start_date) {
      //     let nextDay = new Date(this.subscription_start_date);
      //     nextDay.setDate(nextDay.getDate() + 1);
      //     let nextDayFormatted = nextDay.toISOString().split("T")[0];
      //     let dateEndInput = document.getElementById("dateEndInput");
      //     if (dateEndInput) {
      //         dateEndInput.setAttribute("min", nextDayFormatted);
      //     } else {
      //         
      //     }
      // }

      if (this.subscription_end_date) {
        if (this.subscription_start_date >= this.subscription_end_date) {
          let nextDay = new Date(this.subscription_start_date);
          nextDay.setDate(nextDay.getDate() + 1);
          let nextDayFormatted = nextDay.toISOString().split("T")[0];
          this.subscription_end_date = nextDayFormatted;
        }
      } else {
        if (this.subscription_start_date) {
          // Set the minEndDate to the next day after the selected start date
          let startDate = new Date(this.subscription_start_date);
          this.minEndDate = startDate
          startDate.setDate(startDate.getDate() + 1); // Add one day to start date
          this.minEndDate = startDate.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
          // localStorage.setItem('subscription_end_date', this.minEndDate)
        }
      }
    },



    filteredSubscriptions() {
      return this.getSubscription.filter((item) => item.price !== "0");
    },

    custome_full_days() {
      if (this.subscription_type == 3) {
        return this.selectedDays.map((day) => ({
          day: day.day,
          quantity: day.isChecked ? day.quantity : 0,
        }));
      }
      return null;
    },

    day_date_value() {
      if (this.subscription_type == 3) {
        return this.selectedDays.map((day) => ({
          // key: day.key,
          day: day.day,
          // day: this.date_day.find((d) => d.key == day.key).key,
          quantity: day.isChecked ? day.quantity : 0,
        }));
      }
      return null;
    },

    reformattedDate() {
      return this.subscription_end_date || "Not selected";
    },

    // day_date_value() {
    //   if (this.subscription_type === 3) {
    //     const result = this.selectedDays.map((day) => ({
    //       key: day.key,
    //       day: this.date_day.find((d) => d.key === day.key).key,
    //       quantity: day.isChecked ? day.quantity : 0,
    //     }));

    //     // Save to localStorage
    //     this.subscriptionObj.custom_days = JSON.stringify(result);
    //     localStorage.setItem("custom_days", this.subscriptionObj.custom_days);

    //     return result;
    //   }
    //   return null;
    // },
  },

  methods: {
    ...mapActions("product", ["getProductDetails", "addSubscriptionItem"]),
    ...mapActions("restaurant", ["getRestaurantDetails"]),
    ...mapActions("order", ["postUploadOrderImages", "postremoveOrderImages"]),
    ...mapActions("setting", ["getSubRestaurantSettings"]),
    ...mapMutations("order", ["pickupdelivery"]),



    SubRestaurantSettings() {
      this.getSubRestaurantSettings({
        vendor_id: this.$auth.getVendorId(),
        restaurant_id: this.restaurantId,
        // item_id: parseInt(itemId, 10),
      }).then((data) => {
        if (data.code == 200) {
          this.restaurantSetting = data.settings
        }
      })
    },




    manageSubscriptiopData(val) {
      if (this.subscription_type == 1) {
        let SubeveryDay = val
        SubeveryDay.forEach((item) => {
          this.everyDay_quantity = item.quantity
        })
      } else if (this.subscription_type == 2) {
        this.interval = val;
        const selectedInterval = this.interval.find(item => item.isChecked == true);
        this.intervalDay = selectedInterval ? selectedInterval : { quantity: 0, label: '' };
        // localStorage.setItem('intervalDay', JSON.stringify(this.intervalDay))
      } else if (this.subscription_type == 3) {
        this.selectedDays = val
      } else {
      }
    },


    // getTomorrowDate() {
    //   let today = new Date();
    //   let tomorrow = new Date(today);
    //   tomorrow.setDate(tomorrow.getDate() + 1);
    //   let nextDayDate = tomorrow.getHours() + ':' + tomorrow.getMinutes()

    //   if (nextDayDate < this.$auth.setting && this.$auth.setting.subscription_order_place_at) {
    //     tomorrow.setDate(tomorrow.getDate() + 1); // Increment to the next day
    //     nextDayDate = tomorrow.getFullYear() + '-' + (tomorrow.getMonth() + 1) + '-' + tomorrow.getDate() + ' ' + tomorrow.getHours() + ':' + tomorrow.getMinutes();
    //   }
    //   let dd = String(tomorrow.getDate()).padStart(2, "0");
    //   let mm = String(tomorrow.getMonth() + 1).padStart(2, "0"); // January is 0!
    //   let yyyy = tomorrow.getFullYear();
    //   tomorrow = yyyy + "-" + mm + "-" + dd;
    //   return tomorrow;
    // },

    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      // Extract tomorrow's hours and minutes for comparison
      const tomorrowTime = `${tomorrow.getHours()}:${String(tomorrow.getMinutes()).padStart(2, "0")}`;
      // Compare tomorrow's time with subscription_order_place_at
      const orderPlaceTime = this.$auth.setting && this.$auth.setting.subscription_order_place_at;
      // Check if tomorrow's time has passed the order place time
      let displayDate = tomorrow;
      if (tomorrowTime >= orderPlaceTime) {
        // If the order place time has passed, increment displayDate to the day after tomorrow
        displayDate.setDate(displayDate.getDate() + 1);
      }
      // Format displayDate as YYYY-MM-DD
      const dd = String(displayDate.getDate()).padStart(2, "0");
      const mm = String(displayDate.getMonth() + 1).padStart(2, "0");
      const yyyy = displayDate.getFullYear();
      const formattedDate = `${yyyy}-${mm}-${dd}`;
      return formattedDate;
    },

    getOrdinal(n) {
      const suffixes = ["th", "st", "nd", "rd"];
      const remainder = n % 100;
      return n + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
    },


    // openDatePicker() {
    //   const dateInput = this.$refs.dateInput
    //   // Temporarily bring the date input into view
    //   dateInput.style.position = "static";
    //   dateInput.style.left = "-9999px";
    //   // Focus on the input to open the date picker
    //   dateInput.focus();
    // },
    // updateSelectedDate(event) {
    //   const dateInput = this.$refs.dateInput;

    //   // Update the selected date
    //   this.subscription_end_date = event.target.value;
    //   localStorage.setItem('subscription_end_date', this.subscription_end_date)
    //   // Hide the input again after selecting the date
    //   dateInput.style.position = "absolute";
    //   dateInput.style.left = "-9999px";
    // },

    handleChange() {
      this.subscription_end_date = event.target.value;
      localStorage.setItem('subscription_end_date', this.subscription_end_date)
    },


    closeSubscriptionModal() {
      this.subscriptionModalOPen = false;
      let object = JSON.parse(localStorage.getItem('subscriptionModal_data'))
      this.getSubscription[0].quantity = object.data && object.data[0].quantity
      // this.isShowText = false
      // this.subInervalModal = false
    },

    toggleCustomization() {
      // Toggle the boolean value to show/hide the div
      this.isCustomizationVisible = !this.isCustomizationVisible;
    },

    reformateDate(dateString) {
      if (typeof dateString !== "string" || !dateString) {
        return null; // Return null or a default value to indicate an invalid input
      }
      var parts = dateString.split("/");
      var day = parseInt(parts[0]);
      var month = parseInt(parts[1]);
      var year = parseInt(parts[2]);
      var dateObject = new Date(year, month - 1, day);
      var isoDateString = dateObject.toISOString().slice(0, 10);
      return isoDateString;
    },

    formateDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
      return formattedDate;
    },

    onImgError() {
      this.imgError = true;
    },

    checkNested(e, cust_id) {
      this.selectedCustomisation.customize_type = {
        ...this.selectedCustomisation.customize_type,
        ["parent_" + cust_id]: { value: e.target.value },
      };
    },

    checkNestedSub(e, cust_id, selectionid) {
      let types = Object.keys(this.selectedCustomisation.customize_type);
      this.selectedCustomisation.customize_type = {
        ...this.selectedCustomisation.customize_type,
        /* ...(types.reduce((acc,ire) => {
                      acc[ire] = {...this.selectedCustomisation.customize_type[ire]};
                      return acc;
                  },{})) */
      };
    },

    getNexDayQuantity(selectedDays) {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      var todayIndex = new Date().getDay();
      todayIndex = (todayIndex) % 7;
      // todayIndex = (todayIndex + 1) % 7;
      for (let i = 1; i <= 7; i++) {
        // const nextIndex = (todayIndex + i) % 7;
        const nextIndex = (todayIndex + i) % 7;
        const nextDay = daysOfWeek[nextIndex];
        const nextDayData = this.selectedDays.find((day) => day.key === nextDay);
        if (nextDayData && nextDayData.quantity != 0) {
          return nextDayData.quantity;
        }
      }
      return 0;
    },


    setEndDateAutomatically() {
      const startDate = new Date(this.subscription_start_date);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + parseInt(this.intervalDay.value, 10));
      this.subscription_end_date = endDate.toISOString().slice(0, 10);
      localStorage.setItem('subscription_end_date', this.subscription_end_date)
      // Show confirmation alert
      this.$swal({
        title: 'End Date Set',
        text: `The End Date has been set to ${this.subscription_end_date}`,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    },


    calculateNextDate(dayKey) {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const today = new Date();
      const todayIndex = today.getDay();
      const targetIndex = daysOfWeek.indexOf(dayKey);

      // Calculate the number of days until the next target day
      const daysUntilNext = (targetIndex - todayIndex + 7) % 7 || 7;
      // Calculate the next occurrence date
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + daysUntilNext);

      return nextDate.toISOString().split("T")[0]; // Return as YYYY-MM-DD
    },

    setEndDateSelectedDay(selectedDay) {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const start_date = new Date(this.subscription_start_date);
      const startDayIndex = start_date.getDay();
      const targetIndex = daysOfWeek.indexOf(selectedDay);
      const daysUntilNext = (targetIndex - startDayIndex + 7) % 7 || 7;
      const newEndDate = new Date(start_date);
      newEndDate.setDate(newEndDate.getDate() + daysUntilNext);
      this.subscription_end_date = newEndDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      localStorage.setItem('subscription_end_date', this.subscription_end_date)
      this.$swal({
        title: 'End Date Set',
        text: `The End Date has been set to ${this.subscription_end_date}`,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    },

    buySubscription() {

      // const start_date = new Date(this.subscription_start_date);
      // const end_date = new Date(this.subscription_end_date);
      // // Difference in days between start and end dates
      // const time_Difference = end_date.getTime() - start_date.getTime();
      // const day_Gap = Math.ceil(time_Difference / (1000 * 60 * 60 * 24));
      // if (this.subscription_type == 3) {
      //   let validDayFound = false;
      //   this.selectedDays.forEach((item) => {
      //     if (item.isChecked == true) {
      //       // const nextDate = this.calculateNextDate(item.key);
      //       const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      //       const today = new Date();
      //       const todayIndex = today.getDay();
      //       const targetIndex = daysOfWeek.indexOf(item.key);
      //       // Calculate the number of days until the next target day
      //       const daysUntilNext = (targetIndex - todayIndex + 7) % 7 || 7;
      //       // const nextDateObject = new Date(nextDate);
      //       if (this.subscription_end_date && day_Gap < daysUntilNext) {
      //         this.$swal({
      //           title: this.$auth.setting.app_name,
      //           html: "You have to change End date to continue or you can set the End Date automatically.",
      //           showCancelButton: true,
      //           confirmButtonText: 'Set Date',
      //           cancelButtonText: 'Cancel',
      //           reverseButtons: true,
      //         }).then((result) => {
      //           if (result.isConfirmed) {
      //             // Automatically set the end date by adding the interval to the start date
      //             // this.setEndDateAutomatically();
      //           }
      //         });
      //         return false;
      //       }
      //     }
      //   });
      //   // return
      // }



      // custom selected day 
      const start_date = new Date(this.subscription_start_date);
      const end_date = new Date(this.subscription_end_date);

      // Difference in days between start and end dates
      const time_Difference = end_date.getTime() - start_date.getTime();
      const day_Gap = Math.ceil(time_Difference / (1000 * 60 * 60 * 24));
      if (this.subscription_type == 3) {
        let preventRedirect = false; // Flag to prevent page redirection
        this.selectedDays.forEach((item) => {
          if (item.isChecked == true) {
            const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const today = new Date();
            const todayIndex = today.getDay();
            const targetIndex = daysOfWeek.indexOf(item.key);
            // Calculate the number of days until the next target day
            const daysUntilNext = (targetIndex - todayIndex + 7) % 7 || 7;
            // Calculate the target date for the selected day
            const targetDate = new Date(today);
            targetDate.setDate(today.getDate() + daysUntilNext);
            // Compare targetDate with subscription_end_date
            const isSameAsEndDate = targetDate.toDateString() === end_date.toDateString();

            if (!isSameAsEndDate && (this.subscription_end_date && (day_Gap < daysUntilNext && targetDate > end_date))) {
              preventRedirect = true; // Set the flag to prevent redirection
              this.$swal({
                title: this.$auth.setting.app_name,
                html: "You have to change the End date to continue, or you can set the End Date automatically.",
                showCancelButton: true,
                confirmButtonText: 'Set Date',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  // Automatically set the end date by adding the interval to the start date
                  this.setEndDateSelectedDay(item.key);
                }
              });
              return true; // Exit the loop
            }
          }
        });

        // Redirect logic based on preventRedirect flag
        if (preventRedirect) {
          return; // Prevent redirection
        }
      }

      // const startDate = new Date(this.subscription_start_date);
      // const endDate = new Date(this.subscription_end_date);
      // const timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
      // const dayGap = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert to d

      // if (this.subscription_end_date && dayGap < this.intervalDay.value) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: "You have to change End date to continue or you can set End Date",
      //     confirmButtonText: 'Ok',
      //     reverseButtons: true,
      //   })
      //   return true;
      // }

      // interval selected days 
      const startDate = new Date(this.subscription_start_date);
      const endDate = new Date(this.subscription_end_date);
      const timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
      const dayGap = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days

      // Check if the gap is less than the minimum required value
      if (this.subscription_end_date && this.intervalDay.isModal == true && dayGap < this.intervalDay.value) {
        // Show Swal modal with an option to automatically set the end date
        this.$swal({
          title: this.$auth.setting.app_name,
          html: "You have to change End date to continue or you can set the End Date automatically.",
          showCancelButton: true,
          confirmButtonText: 'Set Date',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Automatically set the end date by adding the interval to the start date
            this.setEndDateAutomatically();
          }
        });
        return true;
      }

      if (!this.subscription_start_date) {
        Vue.toasted.error("Start Date Required", {
          duration: 2000,
        });
        return;
      }


      if (this.$auth.setting.wallet_status === 'inactive' || this.userWalletStatus === 'inactive') {
        Vue.toasted.error("You cannot subscribe as your wallet is not active Please contact the admin ", {
          duration: 2000,
        });
        return;
      } else {
        if (!this.subscription_type) {
          Vue.toasted.error("Please Select Subscription frequency", {
            duration: 2000,
          });
          return;
        }
      }

      // if (this.subscription_type == 1) {
      //   this.getSubscription[0].quantity = 0
      // }
      // if (!this.subscription_type || this.getSubscription[0].quantity == 0) {
      //   Vue.toasted.error("Please Select Subscription frequency", {
      //     duration: 2000,
      //   });
      //   return;
      // }

      if (this.subscription_type == 1) {
        if (this.everyDay_quantity == 0) {
          Vue.toasted.error("Please Select Subscription frequency", {
            duration: 2000,
          });
          return;
        }
      }

      if (this.subscription_type == 1) {
        if (this.everyDay_quantity == null && this.everyDay_quantity == 0) {
          Vue.toasted.error("Please Select Subscription frequency", {
            duration: 2000,
          });
          return;
        }
        if (this.getSubscription[0].quantity == 0) {
          Vue.toasted.error("Please Select Subscription frequency", {
            duration: 2000,
          });
          return;
        }
      }
      if (this.subscription_type == 2) {
        // if (!this.interval.value) {
        //   Vue.toasted.error("Please Select Subscription frequency", {
        //     duration: 2000,
        //   });
        //   return;
        // }

        if (this.interval.quantity == 0) {
          Vue.toasted.error("Please Provide Quantity", {
            duration: 2000,
          });
          return;
        }

        if (this.subscription_type === 2) {
          if (Array.isArray(this.intervalDay)) {
            const isValid = this.intervalDay.some(day => day.isChecked && day.quantity > 1);
            if (!isValid) {
              Vue.toasted.error("Please select a subscription frequency.", {
                duration: 2000,
              });
              return;
            }
          }
        }

        // const hasZeroQuantity = this.intervalDay.some(item => item.isChecked && item.quantity > 0);
        // if (hasZeroQuantity == false) {
        //   Vue.toasted.error("No day selected or quantity is not more than zero.", {
        //     duration: 2000,
        //   });
        //   return;  // Stop execution if the condition is met
        // }
      }

      if (this.subscription_type == 3) {
        const isValid = this.selectedDays.some(
          (day) => day.isChecked && day.quantity > 0
        );

        if (!isValid) {
          Vue.toasted.error(
            "Please Select Subscription frequency.",
            {
              duration: 2000,
            }
          );
          return;
        }
      }

      let itemDetails = [
        {
          item: this.getSubscription[0].item_id,
          quantity: 0,
        },
      ];
      this.subscriptionObj.restaurant_id = this.restaurantId;
      this.subscriptionObj.vendor_id = this.$auth.getVendorId();
      this.subscriptionObj.user_id = this.$auth.user.user_id;
      this.subscriptionObj.subscription_type = this.subscription_type;
      this.subscriptionObj.start_date = this.formateDate(
        this.subscription_start_date
      );

      if (this.subscription_end_date) {
        this.subscriptionObj.end_date = this.formateDate(
          this.subscription_end_date
        );
      }
      (this.subscriptionObj.name = this.getSubscription[0].name),
        (this.subscriptionObj.price = this.getSubscription[0].price),
        (this.subscriptionObj.selected_type =
          this.getSubscription[0].selected_type),
        (this.subscriptionObj.itemTotalQuantity =
          this.getSubscription[0].itemTotalQuantity),
        (this.subscriptionObj.selectCustoizeID =
          this.getSubscription[0].selectCustoizeID),
        (this.subscriptionObj.itemQuntity =
          this.getSubscription[0].itemQuntity),
        (this.subscriptionObj.selectCustoizePrice =
          this.getSubscription[0].selectCustoizePrice),
        (this.subscriptionObj.item_type = this.getSubscription[0].item_type),
        (this.subscriptionObj.item_price = this.getSubscription[0].item_price),
        (this.subscriptionObj.item_id = this.getSubscription[0].item_id),
        (this.subscriptionObj.image = this.getSubscription[0].image),
        (this.subscriptionObj.saveprice = this.getSubscription[0].saveprice),
        (this.subscriptionObj.item_tax = this.getSubscription[0].item_tax),
        (this.subscriptionObj.mrp = this.getSubscription[0].mrp),
        (this.subscriptionObj.packaging_charge =
          this.getSubscription[0].packaging_charge);

      if (this.subscription_type == 1) {
        this.subscriptionObj.interval = 1;
        itemDetails.quantity = this.everyDay_quantity;
        this.subscriptionObj.quantity = this.getSubscription[0].quantity;
      } else if (this.subscription_type == 2) {
        itemDetails.quantity = this.intervalDay.quantity;
        this.subscriptionObj.quantity = this.intervalDay.quantity
        this.subscriptionObj.intervalDay = this.intervalDay.value;
        // this.subscriptionObj.quantity = this.intervalData.quantity
        // this.subscriptionObj.intervalDay = this.intervalData.value;
        // this.subscriptionObj.interval = this.intervalDay.value;
      } else if (this.subscription_type == 3) {
        this.subscriptionObj.quantity = this.getNexDayQuantity(
          this.day_date_value
        );

        itemDetails[0].quantity = this.getNexDayQuantity(this.day_date_value);
        this.subscriptionObj.custom_days = JSON.stringify(this.day_date_value);
        localStorage.setItem("subscribe-custom", JSON.stringify(this.custome_full_days));
      }
      this.subscriptionObj.restaurant_item_details = itemDetails;

      localStorage.setItem(
        "subscribed-data",
        JSON.stringify(this.subscriptionObj)
      );
      localStorage.setItem("subscribedType", this.subscription_type);

      if (this.getSubscription[0].customize) {
        if (this.$v.selectedCustomisation.customize_type.$invalid) {
          return false;
        }
        let pvalue = null;
        let arraySelectCustoizeID = [];
        let arraySelectCustoizePrice = [];
        let arraySelectCustoizPackge = [];
        let selected_type = [];
        let packagingCharge = 0;

        this.productDetail.customize_type.forEach((itm, index) => {
          // let parentId = 'parent_'+itm.customize_type_id;
          pvalue =
            this.selectedCustomisation.customize_type[
              "parent_" + itm.customize_type_id
            ].value;
          if (itm.option) {
            itm.option.forEach((itmoption, key) => {
              if (pvalue && pvalue != null && typeof pvalue != "number") {
                if (
                  pvalue &&
                  pvalue != null &&
                  pvalue.includes(itmoption.selection_id)
                ) {
                  arraySelectCustoizeID.push(itmoption.selection_id);
                  arraySelectCustoizePrice.push(itmoption.selection_price);
                  selected_type.push(itmoption.selection_name);
                  arraySelectCustoizPackge.push(
                    parseInt(itmoption.packaging_charge)
                  );
                  if (itmoption.options) {
                    let temp = null;
                    itmoption.options.forEach((option, okey) => {
                      temp =
                        this.selectedCustomisation.customize_type[
                          "parent_" + itm.customize_type_id
                        ]["child_" + option.parent_item_id].value;
                      if (
                        temp &&
                        typeof temp == "number" &&
                        temp == option.selection_id
                      ) {
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(
                          parseInt(option.packaging_charge)
                        );
                      }
                      if (
                        temp &&
                        typeof temp == "object" &&
                        temp.includes(option.selection_id)
                      ) {
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(
                          parseInt(option.packaging_charge)
                        );
                      }
                    });
                  }
                }
              } else if (
                pvalue &&
                pvalue != null &&
                typeof pvalue == "number"
              ) {
                if (pvalue && pvalue == itmoption.selection_id) {
                  arraySelectCustoizeID.push(itmoption.selection_id);
                  arraySelectCustoizePrice.push(itmoption.selection_price);
                  selected_type.push(itmoption.selection_name);
                  arraySelectCustoizPackge.push(
                    parseInt(itmoption.packaging_charge)
                  );
                  if (itmoption.options) {
                    let temp = null;
                    itmoption.options.forEach((option, okey) => {
                      temp =
                        this.selectedCustomisation.customize_type[
                          "parent_" + itm.customize_type_id
                        ]["child_" + option.parent_item_id].value;
                      if (
                        temp &&
                        typeof temp == "number" &&
                        temp == option.selection_id
                      ) {
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(
                          parseInt(option.packaging_charge)
                        );
                      }
                      if (
                        temp &&
                        typeof temp == "object" &&
                        temp.includes(option.selection_id)
                      ) {
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(
                          parseInt(option.packaging_charge)
                        );
                      }
                    });
                  }
                }
              }
            });
          }
        });
        this.subscriptionObj.selected_type = selected_type;
        this.subscriptionObj.selectCustoizeID = arraySelectCustoizeID;
        this.subscriptionObj.selectCustoizePrice = arraySelectCustoizePrice;
      }
      localStorage.setItem(
        "subscriptionObj",
        JSON.stringify(this.subscriptionObj)
      );
      this.addSubscriptionItem(this.subscriptionObj);
      this.$router.push({ name: "subscription-cart" });
    },

    addIntrvalDay() {
      this.intervalModalvalue = Number(this.intervalInput);
      $("#subInervalModal").modal("hide");
      $("#deliveryDayModal").modal("show");
    },

    //Get Restaurant Information
    getRestaurantInfo() {
      if (this.getSubscription.length) {
        // this.restaurantId = this.products[0].restaurant_id;
        this.getRestaurantDetails({
          vendor_id: this.$auth.getVendorId(),
          restaurant_id: this.restaurantId,
          is_langauge: this.$store.state.lang.locale,
          latitude: parseFloat(localStorage.getItem("latitude")),
          longitude: parseFloat(localStorage.getItem("longitude")),
        }).then((data) => {
          if (data.code == 200) {
            this.restaurantData = data.Result;
            this.restaurant_service_tax = data.Result.restaurant_service_tax;
            this.restaurantLat = data.Result.latitude;
            this.restaurantLong = data.Result.longitude;
            this.deliveryTypeSlots = data.Result.delivery_type_time_slots;
            this.instructionPlaceholder =
              this.$t("any special request for ") + data.Result.name;
            this.isRestaurantClosed =
              data.Result.restaurant_on_off == "0" ? false : true;
            //this.getDeliveryChrge();
          }
        });
      }
    },


    getProductData(itemId, restID) {
      this.itemTotal = 0;
      this.quantityTotal = 1;
      this.loading = true;
      this.customize_skelloader = true;
      this.getProductDetails({
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
        latitude: localStorage.getItem("latitude"),
        starttime: "",
        longitude: localStorage.getItem("longitude"),
        restaurant_id: this.restaurantId,
        date: "",
        item_id: itemId,
        // item_id: parseInt(itemId, 10),
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          if (data.Result[0] != undefined && data.Result[0].length != 0) {
            this.customize_skelloader = false;
            this.productDetail = {
              ...data.Result[0],
              selected_type: data.Result[0].customize_type.map((p) => ({
                customize_type_id: p.customize_type_id,
                max_selection: parseInt(p.is_selection),
                min_selection: parseInt(p.is_min_selection),
                selection_name: p.name,

                ...(p.is_selection != 1
                  ? {
                    option: p.option.filter((q) => q.is_default == "1"),
                  }
                  : {
                    option: [],
                  }),
                ...(p.is_selection == 1
                  ? {
                    roption: p.option.find((q) => q.is_default == "1"),
                  }
                  : {
                    roption: undefined,
                  }),
              })),
            };
            this.tags = this.productDetail.item_tags;
          }
        }
      });
    },

    //Display Total Amount Of item cart
    // getTotalItemPrice(item) {
    //   return item.price * item.quantity;
    // },
    //Get Total Amount of Items

    // closeModal() {
    //   $("#deliveryDayTwoModal").modal("hide");
    //   $("#deliveryDayModal").modal("hide");
    //   $("#everyDayModal").modal("hide");
    // },
    showCustomModal() {
      this.subscriptionModalOPen = true;
    },

    subscriptionModal(type) {
      this.subscription_type = type;
      this.subscriptionModalOPen = true;
      this.subInervalModal = false
      // this.getSubscription[0].quantity = 
    },

    getUserDetail() {
      Login.getUserDetails({
        is_langauge: localStorage.getItem('localelang'),
        latitude: localStorage.getItem('latitude'),
        longitude: localStorage.getItem('longitude')
      }).then(response => {
        this.userWalletStatus = response.data.user_details.wallet_status;
      })
    }

  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-blue-500 {
  color: #3b82f6;
}

.underline {
  text-decoration: underline;
}

.modal-btn.rounded-pill.active {
  background-color: #868e96;
  /* Background color when active */
}

.text-primary-400 {
  color: rgba(var(--theme-primary));
}

input[type="date"] {
  outline: transparent;
}

.card {
  border-radius: 15px;
}

.date-label {
  font-size: 20px;
}

.modal-btn {
  padding: 10px 20px;
  border-radius: 5rem;
  background-color: #e9e9e9;
  border: none;
}

.date-wrapper {
  overflow: auto;
}

.date-list {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 3px;
}

.date-item {
  background-color: #e9e9e9;
  border-radius: 10px;
  min-width: 50px;
  text-align: center;
  font-size: 14px;
}

.date-day {
  background-color: rgba(var(--theme-primary));
  color: #fff;
  padding: 6px;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-num {
  padding: 6px;
}

.subs-list .form-group {
  border: none;
}

.subs-list li {
  border-bottom: 1px solid var(--theme-secondary);
}

input#dateEndInput {
  display: inline-block;
  position: relative;
}

input#dateStartInput {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.silkprod .testimonial-item .item-img {
  display: block !important;
  width: 70% !important;
  margin: 0 auto !important;
  float: left !important;
  height: 183px !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  border-radius: 10px !important;
}

/* .center-card-box{
padding: 0 15px;
} */
</style>
